'use client';

import { LucideProps } from 'lucide-react';

export const CirclesMain = (props: LucideProps) => (
  <svg
    width='450'
    height='556'
    viewBox='0 0 450 556'
    fill='none'
    {...props}
  >
    <circle
      cx='277'
      cy='63'
      r='225'
      fill='url(#paint0_linear_25:217)'
    />
    <circle
      cx='17.9997'
      cy='182'
      r='18'
      fill='url(#paint1_radial_25:217)'
    />
    <circle
      cx='76.9997'
      cy='288'
      r='34'
      fill='url(#paint2_radial_25:217)'
    />
    <circle
      cx='325.486'
      cy='302.87'
      r='180'
      transform='rotate(-37.6852 325.486 302.87)'
      fill='url(#paint3_linear_25:217)'
    />
    <circle
      opacity='0.8'
      cx='184.521'
      cy='315.521'
      r='132.862'
      transform='rotate(114.874 184.521 315.521)'
      stroke='url(#paint4_linear_25:217)'
    />
    <circle
      opacity='0.8'
      cx='356'
      cy='290'
      r='179.5'
      transform='rotate(-30 356 290)'
      stroke='url(#paint5_linear_25:217)'
    />
    <circle
      opacity='0.8'
      cx='191.659'
      cy='302.659'
      r='133.362'
      transform='rotate(133.319 191.659 302.659)'
      fill='url(#paint6_linear_25:217)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_25:217'
        x1='-54.5003'
        y1='-178'
        x2='222'
        y2='288'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#4A6CF7' />
        <stop
          offset='1'
          stopColor='#4A6CF7'
          stopOpacity='0'
        />
      </linearGradient>
      <radialGradient
        id='paint1_radial_25:217'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(17.9997 182) rotate(90) scale(18)'
      >
        <stop
          offset='0.145833'
          stopColor='#4A6CF7'
          stopOpacity='0'
        />
        <stop
          offset='1'
          stopColor='#4A6CF7'
          stopOpacity='0.08'
        />
      </radialGradient>
      <radialGradient
        id='paint2_radial_25:217'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(76.9997 288) rotate(90) scale(34)'
      >
        <stop
          offset='0.145833'
          stopColor='#4A6CF7'
          stopOpacity='0'
        />
        <stop
          offset='1'
          stopColor='#4A6CF7'
          stopOpacity='0.08'
        />
      </radialGradient>
      <linearGradient
        id='paint3_linear_25:217'
        x1='226.775'
        y1='-66.1548'
        x2='292.157'
        y2='351.421'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#4A6CF7' />
        <stop
          offset='1'
          stopColor='#4A6CF7'
          stopOpacity='0'
        />
      </linearGradient>
      <linearGradient
        id='paint4_linear_25:217'
        x1='184.521'
        y1='182.159'
        x2='184.521'
        y2='448.882'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#4A6CF7' />
        <stop
          offset='1'
          stopColor='white'
          stopOpacity='0'
        />
      </linearGradient>
      <linearGradient
        id='paint5_linear_25:217'
        x1='356'
        y1='110'
        x2='356'
        y2='470'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#4A6CF7' />
        <stop
          offset='1'
          stopColor='white'
          stopOpacity='0'
        />
      </linearGradient>
      <linearGradient
        id='paint6_linear_25:217'
        x1='118.524'
        y1='29.2497'
        x2='166.965'
        y2='338.63'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#4A6CF7' />
        <stop
          offset='1'
          stopColor='#4A6CF7'
          stopOpacity='0'
        />
      </linearGradient>
    </defs>
  </svg>
);
export const LinesMain = (props: LucideProps) => (
  <svg
    width='364'
    height='201'
    viewBox='0 0 364 201'
    fill='none'
    {...props}
  >
    <path
      d='M5.88928 72.3303C33.6599 66.4798 101.397 64.9086 150.178 105.427C211.155 156.076 229.59 162.093 264.333 166.607C299.076 171.12 337.718 183.657 362.889 212.24'
      stroke='url(#paint0_linear_25:218)'
    />
    <path
      d='M-22.1107 72.3303C5.65989 66.4798 73.3965 64.9086 122.178 105.427C183.155 156.076 201.59 162.093 236.333 166.607C271.076 171.12 309.718 183.657 334.889 212.24'
      stroke='url(#paint1_linear_25:218)'
    />
    <path
      d='M-53.1107 72.3303C-25.3401 66.4798 42.3965 64.9086 91.1783 105.427C152.155 156.076 170.59 162.093 205.333 166.607C240.076 171.12 278.718 183.657 303.889 212.24'
      stroke='url(#paint2_linear_25:218)'
    />
    <path
      d='M-98.1618 65.0889C-68.1416 60.0601 4.73364 60.4882 56.0734 102.431C120.248 154.86 139.905 161.419 177.137 166.956C214.37 172.493 255.575 186.165 281.856 215.481'
      stroke='url(#paint3_linear_25:218)'
    />
    {/* <circle
      opacity="0.8"
      cx="214.505"
      cy="60.5054"
      r="49.7205"
      transform="rotate(-13.421 214.505 60.5054)"
      stroke="url(#paint4_linear_25:218)"
    /> */}
    {/* <circle cx="220" cy="63" r="43" fill="url(#paint5_radial_25:218)" /> */}
    <defs>
      <linearGradient
        id='paint0_linear_25:218'
        x1='184.389'
        y1='69.2405'
        x2='184.389'
        y2='212.24'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          stopColor='currentColor'
          stopOpacity='0'
        />
        <stop
          offset='1'
          stopColor='currentColor'
        />
      </linearGradient>
      <linearGradient
        id='paint1_linear_25:218'
        x1='156.389'
        y1='69.2405'
        x2='156.389'
        y2='212.24'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          stopColor='currentColor'
          stopOpacity='0'
        />
        <stop
          offset='1'
          stopColor='currentColor'
        />
      </linearGradient>
      <linearGradient
        id='paint2_linear_25:218'
        x1='125.389'
        y1='69.2405'
        x2='125.389'
        y2='212.24'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          stopColor='currentColor'
          stopOpacity='0'
        />
        <stop
          offset='1'
          stopColor='currentColor'
        />
      </linearGradient>
      <linearGradient
        id='paint3_linear_25:218'
        x1='93.8507'
        y1='67.2674'
        x2='89.9278'
        y2='210.214'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          stopColor='currentColor'
          stopOpacity='0'
        />
        <stop
          offset='1'
          stopColor='currentColor'
        />
      </linearGradient>
      <linearGradient
        id='paint4_linear_25:218'
        x1='214.505'
        y1='10.2849'
        x2='212.684'
        y2='99.5816'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='currentColor' />
        <stop
          offset='1'
          stopColor='currentColor'
          stopOpacity='0'
        />
      </linearGradient>
      <radialGradient
        id='paint5_radial_25:218'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(220 63) rotate(90) scale(43)'
      >
        <stop
          offset='0.145833'
          stopColor='white'
          stopOpacity='0'
        />
        <stop
          offset='1'
          stopColor='white'
          stopOpacity='0.08'
        />
      </radialGradient>
    </defs>
  </svg>
);

export const TriangleOne = (props: LucideProps) => {
  return (
    <svg
      width='57'
      height='65'
      viewBox='0 0 57 65'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.5'
        d='M0.407629 15.9573L39.1541 64.0714L56.4489 0.160793L0.407629 15.9573Z'
        fill='url(#paint0_linear_1028_600)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1028_600'
          x1='-18.3187'
          y1='55.1044'
          x2='37.161'
          y2='15.3509'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor={'#4A6CF7'}
            stopOpacity='0.62'
          />
          <stop
            offset='1'
            stopColor={'#4A6CF7'}
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const TriangleTwo = (props: LucideProps) => {
  return (
    <svg
      width='39'
      height='32'
      viewBox='0 0 39 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.5'
        d='M14.7137 31.4215L38.6431 4.24115L6.96581e-07 0.624124L14.7137 31.4215Z'
        fill='url(#paint0_linear_1028_601)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1028_601'
          x1='39.1948'
          y1='38.335'
          x2='10.6982'
          y2='10.2511'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor={'#4A6CF7'}
            stopOpacity='0.62'
          />
          <stop
            offset='1'
            stopColor={'#4A6CF7'}
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const CircleOne = (props: LucideProps) => {
  return (
    <svg
      width='38'
      height='38'
      viewBox='0 0 38 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.5'
        d='M10.6763 35.3091C23.3976 41.6367 38.1681 31.7045 37.107 17.536C36.1205 4.3628 21.9407 -3.46901 10.2651 2.71063C-2.92254 9.69061 -2.68321 28.664 10.6763 35.3091Z'
        fill='url(#paint0_linear_1028_602)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1028_602'
          x1='-0.571054'
          y1='-37.1717'
          x2='28.7937'
          y2='26.7564'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor={'#4A6CF7'}
            stopOpacity='0.62'
          />
          <stop
            offset='1'
            stopColor={'#4A6CF7'}
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const LinesOne = (props: LucideProps) => {
  return (
    <svg
      width='162'
      height='91'
      viewBox='0 0 162 91'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g opacity='0.3'>
        <path
          opacity='0.45'
          d='M1 89.9999C8 77.3332 27.7 50.7999 50.5 45.9999C79 39.9999 95 41.9999 106 30.4999C117 18.9999 126 -3.50014 149 -3.50014C172 -3.50014 187 4.99986 200.5 -8.50014C214 -22.0001 210.5 -46.0001 244 -37.5001C270.8 -30.7001 307.167 -45 322 -53'
          stroke='url(#paint0_linear_1028_603)'
        />
        <path
          opacity='0.45'
          d='M43 64.9999C50 52.3332 69.7 25.7999 92.5 20.9999C121 14.9999 137 16.9999 148 5.49986C159 -6.00014 168 -28.5001 191 -28.5001C214 -28.5001 229 -20.0001 242.5 -33.5001C256 -47.0001 252.5 -71.0001 286 -62.5001C312.8 -55.7001 349.167 -70 364 -78'
          stroke='url(#paint1_linear_1028_603)'
        />
        <path
          opacity='0.45'
          d='M4 73.9999C11 61.3332 30.7 34.7999 53.5 29.9999C82 23.9999 98 25.9999 109 14.4999C120 2.99986 129 -19.5001 152 -19.5001C175 -19.5001 190 -11.0001 203.5 -24.5001C217 -38.0001 213.5 -62.0001 247 -53.5001C273.8 -46.7001 310.167 -61 325 -69'
          stroke='url(#paint2_linear_1028_603)'
        />
        <path
          opacity='0.45'
          d='M41 40.9999C48 28.3332 67.7 1.79986 90.5 -3.00014C119 -9.00014 135 -7.00014 146 -18.5001C157 -30.0001 166 -52.5001 189 -52.5001C212 -52.5001 227 -44.0001 240.5 -57.5001C254 -71.0001 250.5 -95.0001 284 -86.5001C310.8 -79.7001 347.167 -94 362 -102'
          stroke='url(#paint3_linear_1028_603)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1028_603'
          x1='291.35'
          y1='12.1032'
          x2='179.211'
          y2='237.617'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset='0.328125'
            stopColor={'#4A6CF7'}
          />
          <stop
            offset='1'
            stopColor={'#4A6CF7'}
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1028_603'
          x1='333.35'
          y1='-12.8968'
          x2='221.211'
          y2='212.617'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset='0.328125'
            stopColor={'#4A6CF7'}
          />
          <stop
            offset='1'
            stopColor={'#4A6CF7'}
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1028_603'
          x1='294.35'
          y1='-3.89678'
          x2='182.211'
          y2='221.617'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset='0.328125'
            stopColor={'#4A6CF7'}
          />
          <stop
            offset='1'
            stopColor={'#4A6CF7'}
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1028_603'
          x1='331.35'
          y1='-36.8968'
          x2='219.211'
          y2='188.617'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            offset='0.328125'
            stopColor={'#4A6CF7'}
          />
          <stop
            offset='1'
            stopColor={'#4A6CF7'}
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const WaveOne = (props: LucideProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1440 320'
      fill='none'
      {...props}
    >
      <path
        fill={props.fill ?? 'hsl(var(--primary))'}
        fill-opacity='1'
        d='M0,224L1440,64L1440,0L0,0Z'
      ></path>
    </svg>
  );
};

export const WaveTwo = (props: LucideProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1440 320'
      fill='none'
      {...props}
    >
      <path
        fillOpacity='1'
        d='M0,288L1440,160L1440,0L0,0Z'
      ></path>
    </svg>
  );
};

export const BlocksOne = (props: LucideProps) => {
  return (
    <svg
      width='406'
      height='286'
      viewBox='0 0 406 286'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g opacity='0.5'>
        <rect
          opacity='0.5'
          x='56.25'
          y='110.344'
          width='116.719'
          height='116.438'
          stroke='url(#paint0_linear_116:1140)'
        />
        <rect
          opacity='0.1'
          x='56.25'
          y='110.344'
          width='116.719'
          height='116.438'
          fill='url(#paint1_linear_116:1140)'
        />
        <path
          opacity='0.5'
          d='M172.688 110.344L229.219 51V167.601L172.688 226.781V110.344Z'
          stroke='url(#paint2_linear_116:1140)'
        />
        <path
          opacity='0.1'
          d='M172.688 110.344L229.219 51V167.601L172.688 226.781V110.344Z'
          fill='url(#paint3_linear_116:1140)'
        />
        <path
          opacity='0.5'
          d='M0 169.619L56.25 110.344V226.85L0 286.125V169.619Z'
          stroke='url(#paint4_linear_116:1140)'
        />
        <path
          opacity='0.1'
          d='M0 169.619L56.25 110.344V226.85L0 286.125V169.619Z'
          fill='url(#paint5_linear_116:1140)'
        />
        <rect
          opacity='0.5'
          x='228.938'
          y='51.2812'
          width='119.25'
          height='116.438'
          stroke='url(#paint6_linear_116:1140)'
        />
        <rect
          opacity='0.1'
          x='228.938'
          y='51.2812'
          width='119.25'
          height='116.438'
          fill='url(#paint7_linear_116:1140)'
        />
        <path
          opacity='0.5'
          d='M347.906 51.2812L405 2V110.113L347.906 167.719V51.2812Z'
          stroke='url(#paint8_linear_116:1140)'
        />
        <path
          opacity='0.1'
          d='M347.906 51.2812L405 2V110.113L347.906 167.719V51.2812Z'
          fill='url(#paint9_linear_116:1140)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_116:1140'
          x1='49.0781'
          y1='112.313'
          x2='148.922'
          y2='131.859'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_116:1140'
          x1='179.141'
          y1='209.062'
          x2='32.6026'
          y2='145.47'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_116:1140'
          x1='170.016'
          y1='125.25'
          x2='217.542'
          y2='125.507'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_116:1140'
          x1='233.578'
          y1='113.156'
          x2='146.509'
          y2='143.95'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint4_linear_116:1140'
          x1='-3.45633'
          y1='113.316'
          x2='46.311'
          y2='116.426'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint5_linear_116:1140'
          x1='69.8907'
          y1='189.234'
          x2='84.0124'
          y2='249.947'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint6_linear_116:1140'
          x1='218.953'
          y1='157.453'
          x2='330.261'
          y2='148.369'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint7_linear_116:1140'
          x1='348.187'
          y1='46.6406'
          x2='280.112'
          y2='168.552'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint8_linear_116:1140'
          x1='329'
          y1='190'
          x2='369.525'
          y2='-29.8829'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint9_linear_116:1140'
          x1='409'
          y1='10'
          x2='331.729'
          y2='34.2741'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const BlocksTwo = (props: LucideProps) => {
  return (
    <svg
      width='406'
      height='286'
      viewBox='0 0 406 286'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g opacity='0.5'>
        <rect
          opacity='0.5'
          x='57.25'
          y='108.344'
          width='116.719'
          height='116.438'
          stroke='url(#paint0_linear_116:1151)'
        />
        <rect
          opacity='0.1'
          x='57.25'
          y='108.344'
          width='116.719'
          height='116.438'
          fill='url(#paint1_linear_116:1151)'
        />
        <path
          opacity='0.5'
          d='M173.688 108.344L230.219 49V165.601L173.688 224.781V108.344Z'
          stroke='url(#paint2_linear_116:1151)'
        />
        <path
          opacity='0.1'
          d='M173.688 108.344L230.219 49V165.601L173.688 224.781V108.344Z'
          fill='url(#paint3_linear_116:1151)'
        />
        <path
          opacity='0.5'
          d='M1 167.619L57.25 108.344V224.85L1 284.125V167.619Z'
          stroke='url(#paint4_linear_116:1151)'
        />
        <path
          opacity='0.1'
          d='M1 167.619L57.25 108.344V224.85L1 284.125V167.619Z'
          fill='url(#paint5_linear_116:1151)'
        />
        <rect
          opacity='0.5'
          x='229.938'
          y='49.2812'
          width='119.25'
          height='116.438'
          stroke='url(#paint6_linear_116:1151)'
        />
        <rect
          opacity='0.1'
          x='229.938'
          y='49.2812'
          width='119.25'
          height='116.438'
          fill='url(#paint7_linear_116:1151)'
        />
        <path
          opacity='0.5'
          d='M348.906 49.2812L406 0V108.113L348.906 165.719V49.2812Z'
          stroke='url(#paint8_linear_116:1151)'
        />
        <path
          opacity='0.1'
          d='M348.906 49.2812L406 0V108.113L348.906 165.719V49.2812Z'
          fill='url(#paint9_linear_116:1151)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_116:1151'
          x1='50.0781'
          y1='110.313'
          x2='149.922'
          y2='129.859'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_116:1151'
          x1='180.141'
          y1='207.062'
          x2='33.6026'
          y2='143.47'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_116:1151'
          x1='171.016'
          y1='123.25'
          x2='218.542'
          y2='123.507'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_116:1151'
          x1='234.578'
          y1='111.156'
          x2='147.509'
          y2='141.95'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint4_linear_116:1151'
          x1='85.5'
          y1='71.5'
          x2='100.174'
          y2='270.716'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint5_linear_116:1151'
          x1='70.8907'
          y1='187.234'
          x2='85.0124'
          y2='247.947'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint6_linear_116:1151'
          x1='219.953'
          y1='155.453'
          x2='331.261'
          y2='146.369'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint7_linear_116:1151'
          x1='349.187'
          y1='44.6406'
          x2='281.112'
          y2='166.552'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint8_linear_116:1151'
          x1='394.75'
          y1='64.3284'
          x2='409.531'
          y2='110.901'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint9_linear_116:1151'
          x1='410'
          y1='8.00001'
          x2='332.729'
          y2='32.2741'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const NotFound = (props: LucideProps) => {
  return (
    <svg
      className='mx-auto w-full text-center'
      height='210'
      viewBox='0 0 474 210'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M25 162.051H101.211V190H133.308V162.051H153V135.111H133.308V31H91.2871L25 135.577V162.051ZM101.831 135.111H58.8025V133.869L100.591 67.6445H101.831V135.111Z'
        fill='url(#paint0_linear_1028_3014)'
        stroke='url(#paint1_linear_1028_3014)'
        strokeOpacity='0.36'
      />
      <path
        d='M307 132.051H383.211V160H415.308V132.051H435V105.111H415.308V1H373.287L307 105.577V132.051ZM383.831 105.111H340.803V103.869L382.591 37.6445H383.831V105.111Z'
        fill='url(#paint2_linear_1028_3014)'
        stroke='url(#paint3_linear_1028_3014)'
        strokeOpacity='0.36'
      />
      <circle
        opacity='0.8'
        cx='227.5'
        cy='80.5'
        r='68.5'
        fill='#4A6CF7'
      />
      <mask
        id='mask0_1028_3014'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='159'
        y='12'
        width='137'
        height='137'
      >
        <circle
          opacity='0.8'
          cx='227.5'
          cy='80.5'
          r='68.5'
          fill='#4A6CF7'
        />
      </mask>
      <g mask='url(#mask0_1028_3014)'>
        <circle
          opacity='0.8'
          cx='227.5'
          cy='80.5'
          r='68.5'
          fill='url(#paint4_radial_1028_3014)'
        />
        <g
          opacity='0.8'
          filter='url(#filter0_f_1028_3014)'
        >
          <circle
            cx='233.543'
            cy='48.2645'
            r='28.2059'
            fill='white'
          />
        </g>
      </g>
      <path
        opacity='0.5'
        d='M0 181H83.5V208H150.5V177H169.5V147.5C169.5 147.5 194 164 229.5 164C265 164 294 133.5 294 133.5V151H306.5H361V177H435V151H474'
        stroke='url(#paint5_linear_1028_3014)'
        strokeWidth='3'
      />
      <defs>
        <filter
          id='filter0_f_1028_3014'
          x='175.337'
          y='-9.94141'
          width='116.412'
          height='116.412'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='15'
            result='effect1_foregroundBlur_1028_3014'
          />
        </filter>
        <linearGradient
          id='paint0_linear_1028_3014'
          x1='89'
          y1='31'
          x2='89'
          y2='190'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1028_3014'
          x1='25'
          y1='182'
          x2='126.155'
          y2='26.0837'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#93A7F9'
            stopOpacity='0.26'
          />
          <stop
            offset='1'
            stopColor='#4E70FF'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1028_3014'
          x1='371'
          y1='1'
          x2='371'
          y2='160'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1028_3014'
          x1='307'
          y1='152'
          x2='408.155'
          y2='-3.91631'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#93A7F9'
            stopOpacity='0.26'
          />
          <stop
            offset='1'
            stopColor='#4E70FF'
          />
        </linearGradient>
        <radialGradient
          id='paint4_radial_1028_3014'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(227.5 80.5) rotate(90) scale(73.5368)'
        >
          <stop stopOpacity='0.47' />
          <stop
            offset='1'
            stopOpacity='0'
          />
        </radialGradient>
        <linearGradient
          id='paint5_linear_1028_3014'
          x1='1.3176e-06'
          y1='204.252'
          x2='8.46427'
          y2='101.141'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#93A7F9'
            stopOpacity='0.26'
          />
          <stop
            offset='1'
            stopColor='#4E70FF'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const NotFoundOutline = (props: LucideProps) => {
  return (
    <svg
      className='mx-auto w-full text-center'
      height='210'
      viewBox='0 0 474 210'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.5'
        d='M25 163.051H101.211V191H133.308V163.051H153V136.111H133.308V32H91.2871L25 136.577V163.051ZM101.831 136.111H58.8025V134.869L100.591 68.6445H101.831V136.111Z'
        stroke='url(#paint0_linear_116:1137)'
        strokeWidth='3'
      />
      <path
        opacity='0.5'
        d='M307 133.051H383.211V161H415.308V133.051H435V106.111H415.308V2H373.287L307 106.577V133.051ZM383.831 106.111H340.803V104.869L382.591 38.6445H383.831V106.111Z'
        stroke='url(#paint1_linear_116:1137)'
        strokeWidth='3'
      />
      <circle
        opacity='0.8'
        cx='227.5'
        cy='81.5'
        r='68.5'
        fill='#4A6CF7'
      />
      <mask
        id='mask0_116:1137'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='159'
        y='13'
        width='137'
        height='137'
      >
        <circle
          opacity='0.8'
          cx='227.5'
          cy='81.5'
          r='68.5'
          fill='#4A6CF7'
        />
      </mask>
      <g mask='url(#mask0_116:1137)'>
        <circle
          opacity='0.8'
          cx='227.5'
          cy='81.5'
          r='68.5'
          fill='url(#paint2_radial_116:1137)'
        />
        <g
          opacity='0.8'
          filter='url(#filter0_f_116:1137)'
        >
          <circle
            cx='233.543'
            cy='49.2645'
            r='28.2059'
            fill='white'
          />
        </g>
      </g>
      <path
        d='M0 182H83.5V209H150.5V178H169.5V148.5C169.5 148.5 194 165 229.5 165C265 165 294 134.5 294 134.5V152H306.5H361V178H435V152H474'
        stroke='white'
        strokeOpacity='0.08'
        strokeWidth='2'
      />
      <defs>
        <filter
          id='filter0_f_116:1137'
          x='175.337'
          y='-8.94141'
          width='116.412'
          height='116.412'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='15'
            result='effect1_foregroundBlur_116:1137'
          />
        </filter>
        <linearGradient
          id='paint0_linear_116:1137'
          x1='25'
          y1='183'
          x2='126.155'
          y2='27.0837'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_116:1137'
          x1='307'
          y1='153'
          x2='408.155'
          y2='-2.91631'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <radialGradient
          id='paint2_radial_116:1137'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(227.5 81.5) rotate(90) scale(73.5368)'
        >
          <stop stopOpacity='0.47' />
          <stop
            offset='1'
            stopOpacity='0'
          />
        </radialGradient>
      </defs>
    </svg>
  );
};

export const BallOne = (props: LucideProps) => {
  return (
    <svg
      width='55'
      height='99'
      viewBox='0 0 55 99'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle
        opacity='0.8'
        cx='49.5'
        cy='49.5'
        r='49.5'
        fill='#959CB1'
      />
      <mask
        id='mask0_94:899'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='99'
        height='99'
      >
        <circle
          opacity='0.8'
          cx='49.5'
          cy='49.5'
          r='49.5'
          fill='#4A6CF7'
        />
      </mask>
      <g mask='url(#mask0_94:899)'>
        <circle
          opacity='0.8'
          cx='49.5'
          cy='49.5'
          r='49.5'
          fill='url(#paint0_radial_94:899)'
        />
        <g
          opacity='0.8'
          filter='url(#filter0_f_94:899)'
        >
          <circle
            cx='53.8676'
            cy='26.2061'
            r='20.3824'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_f_94:899'
          x='12.4852'
          y='-15.1763'
          width='82.7646'
          height='82.7646'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='10.5'
            result='effect1_foregroundBlur_94:899'
          />
        </filter>
        <radialGradient
          id='paint0_radial_94:899'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(49.5 49.5) rotate(90) scale(53.1397)'
        >
          <stop stopOpacity='0.47' />
          <stop
            offset='1'
            stopOpacity='0'
          />
        </radialGradient>
      </defs>
    </svg>
  );
};

export const CubeOne = (props: LucideProps) => {
  return (
    <svg
      width='79'
      height='94'
      viewBox='0 0 79 94'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        opacity='0.3'
        x='-41'
        y='26.9426'
        width='66.6675'
        height='66.6675'
        transform='rotate(-22.9007 -41 26.9426)'
        fill='url(#paint0_linear_94:889)'
      />
      <rect
        x='-41'
        y='26.9426'
        width='66.6675'
        height='66.6675'
        transform='rotate(-22.9007 -41 26.9426)'
        stroke='url(#paint1_linear_94:889)'
        strokeWidth='0.7'
      />
      <path
        opacity='0.3'
        d='M50.5215 7.42229L20.325 1.14771L46.2077 62.3249L77.1885 68.2073L50.5215 7.42229Z'
        fill='url(#paint2_linear_94:889)'
      />
      <path
        d='M50.5215 7.42229L20.325 1.14771L46.2077 62.3249L76.7963 68.2073L50.5215 7.42229Z'
        stroke='url(#paint3_linear_94:889)'
        strokeWidth='0.7'
      />
      <path
        opacity='0.3'
        d='M17.9721 93.3057L-14.9695 88.2076L46.2077 62.325L77.1885 68.2074L17.9721 93.3057Z'
        fill='url(#paint4_linear_94:889)'
      />
      <path
        d='M17.972 93.3057L-14.1852 88.2076L46.2077 62.325L77.1884 68.2074L17.972 93.3057Z'
        stroke='url(#paint5_linear_94:889)'
        strokeWidth='0.7'
      />
      <defs>
        <linearGradient
          id='paint0_linear_94:889'
          x1='-41'
          y1='21.8445'
          x2='36.9671'
          y2='59.8878'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0.62'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_94:889'
          x1='25.6675'
          y1='95.9631'
          x2='-42.9608'
          y2='20.668'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0.51'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_94:889'
          x1='20.325'
          y1='-3.98039'
          x2='90.6248'
          y2='25.1062'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0.62'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_94:889'
          x1='18.3642'
          y1='-1.59742'
          x2='113.9'
          y2='80.6826'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0.51'
          />
        </linearGradient>
        <linearGradient
          id='paint4_linear_94:889'
          x1='61.1098'
          y1='62.3249'
          x2='-8.82468'
          y2='58.2156'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0.62'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint5_linear_94:889'
          x1='65.4236'
          y1='65.0701'
          x2='24.0178'
          y2='41.6598'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0.51'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const FadedStripesOne = (props: LucideProps) => {
  return (
    <svg
      width='239'
      height='601'
      viewBox='0 0 239 601'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        opacity='0.3'
        x='-184.451'
        y='600.973'
        width='196'
        height='541.607'
        rx='2'
        transform='rotate(-128.7 -184.451 600.973)'
        fill='url(#paint0_linear_93:235)'
      />
      <rect
        opacity='0.3'
        x='-188.201'
        y='385.272'
        width='59.7544'
        height='541.607'
        rx='2'
        transform='rotate(-128.7 -188.201 385.272)'
        fill='url(#paint1_linear_93:235)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_93:235'
          x1='-90.1184'
          y1='420.414'
          x2='-90.1184'
          y2='1131.65'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_93:235'
          x1='-159.441'
          y1='204.714'
          x2='-159.441'
          y2='915.952'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const FadedStripesTwo = (props: LucideProps) => {
  return (
    <svg
      width='238'
      height='531'
      viewBox='0 0 238 531'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        opacity='0.3'
        x='422.819'
        y='-70.8145'
        width='196'
        height='541.607'
        rx='2'
        transform='rotate(51.2997 422.819 -70.8145)'
        fill='url(#paint0_linear_83:2)'
      />
      <rect
        opacity='0.3'
        x='426.568'
        y='144.886'
        width='59.7544'
        height='541.607'
        rx='2'
        transform='rotate(51.2997 426.568 144.886)'
        fill='url(#paint1_linear_83:2)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_83:2'
          x1='517.152'
          y1='-251.373'
          x2='517.152'
          y2='459.865'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_83:2'
          x1='455.327'
          y1='-35.673'
          x2='455.327'
          y2='675.565'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4A6CF7' />
          <stop
            offset='1'
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const StripesOne = (props: LucideProps) => {
  return (
    <svg
      width='264'
      height='392'
      viewBox='0 0 264 392'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g opacity='0.7'>
        <path
          opacity='0.45'
          d='M183.019 117.744C111.297 56.5687 -50.5296 -66.5874 -124.061 -69.8084C-215.976 -73.8347 -168.317 63.6642 -209.623 105.514C-250.93 147.364 -292.566 186.411 -266.992 261.051C-253.388 300.753 -219.5 410.744 7.04198 414.168'
          stroke='url(#paint0_linear_1025_537)'
        />
        <path
          opacity='0.45'
          d='M186.574 130.868C114.852 69.6928 -46.9745 -53.4634 -120.506 -56.6844C-212.421 -60.7107 -164.761 76.7883 -206.068 118.638C-247.375 160.488 -289.011 199.535 -263.437 274.175C-249.833 313.877 -215.945 423.868 10.5972 427.292'
          stroke='url(#paint1_linear_1025_537)'
        />
        <path
          opacity='0.45'
          d='M190.129 143.992C118.408 82.8168 -43.419 -40.3394 -116.951 -43.5604C-208.866 -47.5866 -161.206 89.9123 -202.513 131.762C-243.819 173.612 -285.456 212.659 -259.881 287.299C-246.278 327.001 -212.389 436.992 14.1526 440.416'
          stroke='url(#paint2_linear_1025_537)'
        />
        <path
          opacity='0.45'
          d='M193.684 157.117C121.962 95.9418 -39.8641 -27.2144 -113.396 -30.4354C-205.311 -34.4616 -157.651 103.037 -198.958 144.887C-240.264 186.737 -281.901 225.784 -256.326 300.424C-242.723 340.126 -208.834 450.117 17.7075 453.541'
          stroke='url(#paint3_linear_1025_537)'
        />
        <path
          opacity='0.45'
          d='M197.239 170.241C125.518 109.066 -36.3089 -14.0904 -109.841 -17.3114C-201.756 -21.3376 -154.096 116.161 -195.402 158.011C-236.709 199.861 -278.346 238.908 -252.771 313.548C-239.167 353.25 -205.279 463.241 21.2627 466.665'
          stroke='url(#paint4_linear_1025_537)'
        />
        <path
          opacity='0.45'
          d='M200.795 183.365C129.073 122.19 -32.7535 -0.966327 -106.285 -4.18734C-198.2 -8.2136 -150.54 129.285 -191.847 171.135C-233.154 212.985 -274.79 252.032 -249.216 326.672C-235.612 366.374 -201.724 476.365 24.8181 479.789'
          stroke='url(#paint5_linear_1025_537)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1025_537'
          x1='25.6942'
          y1='359.162'
          x2='-275.985'
          y2='229.254'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1025_537'
          x1='29.2494'
          y1='372.286'
          x2='-272.43'
          y2='242.378'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1025_537'
          x1='32.8048'
          y1='385.41'
          x2='-268.874'
          y2='255.502'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1025_537'
          x1='36.3597'
          y1='398.535'
          x2='-265.319'
          y2='268.627'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint4_linear_1025_537'
          x1='39.9149'
          y1='411.659'
          x2='-261.764'
          y2='281.751'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint5_linear_1025_537'
          x1='43.4703'
          y1='424.783'
          x2='-258.209'
          y2='294.875'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const StripesTwo = (props: LucideProps) => {
  return (
    <svg
      width='383'
      height='202'
      viewBox='0 0 383 202'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g opacity='0.7'>
        <path
          opacity='0.45'
          d='M189.512 513.559C275.927 537.605 464.942 579.022 529.69 552.328C610.623 518.961 514.382 419.406 533.254 366.735C552.126 314.063 572.407 263.677 520.419 209.516C492.766 180.706 419.435 99.3705 222.563 187.218'
          stroke='url(#paint0_linear_1025_544)'
        />
        <path
          opacity='0.45'
          d='M181.184 503.659C267.598 527.704 456.614 569.121 521.361 542.428C602.295 509.061 506.053 409.506 524.926 356.834C543.798 304.162 564.078 253.777 512.091 199.615C484.438 170.806 411.107 89.4702 214.234 177.318'
          stroke='url(#paint1_linear_1025_544)'
        />
        <path
          opacity='0.45'
          d='M172.855 493.758C259.27 517.803 448.285 559.22 513.033 532.527C593.966 499.16 497.725 399.605 516.597 346.933C535.47 294.261 555.75 243.876 503.762 189.714C476.109 160.905 402.778 79.5688 205.906 167.416'
          stroke='url(#paint2_linear_1025_544)'
        />
        <path
          opacity='0.45'
          d='M164.527 483.856C250.941 507.901 439.957 549.319 504.704 522.625C585.638 489.258 489.396 389.703 508.269 337.031C527.141 284.36 547.421 233.974 495.434 179.813C467.781 151.003 394.45 69.6674 197.577 157.515'
          stroke='url(#paint3_linear_1025_544)'
        />
        <path
          opacity='0.45'
          d='M156.198 473.956C242.613 498.001 431.628 539.418 496.375 512.725C577.309 479.358 481.068 379.803 499.94 327.131C518.812 274.459 539.093 224.074 487.105 169.912C459.452 141.103 386.121 59.767 189.249 147.615'
          stroke='url(#paint4_linear_1025_544)'
        />
        <path
          opacity='0.45'
          d='M147.87 464.055C234.284 488.101 423.3 529.518 488.047 502.824C568.981 469.458 472.739 369.903 491.612 317.231C510.484 264.559 530.764 214.174 478.777 160.012C451.124 131.202 377.792 49.8666 180.92 137.714'
          stroke='url(#paint5_linear_1025_544)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1025_544'
          x1='228.514'
          y1='242.163'
          x2='540.925'
          y2='233.351'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1025_544'
          x1='220.186'
          y1='232.263'
          x2='532.596'
          y2='223.451'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1025_544'
          x1='211.857'
          y1='222.362'
          x2='524.268'
          y2='213.549'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1025_544'
          x1='203.529'
          y1='212.46'
          x2='515.939'
          y2='203.648'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint4_linear_1025_544'
          x1='195.2'
          y1='202.56'
          x2='507.611'
          y2='193.748'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint5_linear_1025_544'
          x1='186.872'
          y1='192.66'
          x2='499.282'
          y2='183.847'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const StripesThree = (props: LucideProps) => {
  return (
    <svg
      width='279'
      height='106'
      viewBox='0 0 279 106'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g opacity='0.5'>
        <path
          d='M-57 12L50.0728 74.8548C55.5501 79.0219 70.8513 85.7589 88.2373 79.3692C109.97 71.3821 116.861 60.9642 156.615 63.7423C178.778 65.291 195.31 69.2985 205.911 62.3533C216.513 55.408 224.994 47.7682 243.016 49.1572C255.835 50.1453 265.278 50.8936 278 45.3373'
          stroke='url(#paint0_linear_72:302)'
        />
        <path
          d='M-57 1L50.0728 63.8548C55.5501 68.0219 70.8513 74.7589 88.2373 68.3692C109.97 60.3821 116.861 49.9642 156.615 52.7423C178.778 54.291 195.31 58.2985 205.911 51.3533C216.513 44.408 224.994 36.7682 243.016 38.1572C255.835 39.1453 265.278 39.8936 278 34.3373'
          stroke='url(#paint1_linear_72:302)'
        />
        <path
          d='M-57 23L50.0728 85.8548C55.5501 90.0219 70.8513 96.7589 88.2373 90.3692C109.97 82.3821 116.861 71.9642 156.615 74.7423C178.778 76.291 195.31 80.2985 205.911 73.3533C216.513 66.408 224.994 58.7682 243.016 60.1572C255.835 61.1453 265.278 61.8936 278 56.3373'
          stroke='url(#paint2_linear_72:302)'
        />
        <path
          d='M-57 35L50.0728 97.8548C55.5501 102.022 70.8513 108.759 88.2373 102.369C109.97 94.3821 116.861 83.9642 156.615 86.7423C178.778 88.291 195.31 92.2985 205.911 85.3533C216.513 78.408 224.994 70.7682 243.016 72.1572C255.835 73.1453 265.278 73.8936 278 68.3373'
          stroke='url(#paint3_linear_72:302)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_72:302'
          x1='256.267'
          y1='53.6717'
          x2='-40.8688'
          y2='8.15715'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_72:302'
          x1='256.267'
          y1='42.6717'
          x2='-40.8688'
          y2='-2.84285'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_72:302'
          x1='256.267'
          y1='64.6717'
          x2='-40.8688'
          y2='19.1572'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_72:302'
          x1='256.267'
          y1='76.6717'
          x2='-40.8688'
          y2='31.1572'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ZapOne = (props: LucideProps) => {
  return (
    <svg
      width='1440'
      height='563'
      viewBox='0 0 1440 563'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 87L263.536 261L923.37 1L1440 225.5M1440 302L973.591 238.5L276.961 562L0 375'
        stroke='url(#paint0_linear_42_239)'
      />
      <path
        d='M276.808 561.5L263.817 261L0 87V375L276.808 561.5Z'
        fill='url(#paint1_linear_42_239)'
      />
      <path
        d='M339.764 231L263.817 261L276.808 562L549.618 435L339.764 231Z'
        fill='url(#paint2_linear_42_239)'
      />
      <path
        d='M1439 225L1103.23 79V256L1439 302V225Z'
        fill='url(#paint3_linear_42_239)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_42_239'
          x1='720'
          y1='1'
          x2='720'
          y2='562'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0'
          />
          <stop
            offset='1'
            stopColor='#4A6CF7'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_42_239'
          x1='-18.9868'
          y1='176.5'
          x2='202.082'
          y2='533.363'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0.5'
          />
          <stop
            offset='1'
            stopColor='#2441B6'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_42_239'
          x1='276.308'
          y1='609'
          x2='430.232'
          y2='334.49'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0.5'
          />
          <stop
            offset='1'
            stopColor='#2441B6'
            stopOpacity='0'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_42_239'
          x1='1448'
          y1='274.5'
          x2='1359.41'
          y2='119.613'
          gradientUnits='userSpaceOnUse'
        >
          <stop
            stopColor='#4A6CF7'
            stopOpacity='0.31'
          />
          <stop
            offset='1'
            stopColor='#2441B6'
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const RenderSVGs = () => {
  return (<div>
    <CirclesMain className='mx-auto'/>
    <LinesMain className='mx-auto'/>
    <TriangleOne className='mx-auto'/>
    <TriangleTwo className='mx-auto'/>
    <CircleOne className='mx-auto'/>
    <LinesOne className='mx-auto'/>
    <BlocksOne className='mx-auto'/>
    <BlocksTwo className='mx-auto'/>
    <NotFound className='mx-auto'/>
    <NotFoundOutline className='mx-auto'/>
    <BallOne className='mx-auto'/>
    <CubeOne className='mx-auto'/>
    <FadedStripesOne className='mx-auto'/>
    <FadedStripesTwo className='mx-auto'/>
    <StripesOne className='mx-auto'/>
    <StripesTwo className='mx-auto'/>
    <StripesThree className='mx-auto'/>
    <ZapOne className='mx-auto'/>
  </div>);
};
